body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.footer {
  width: 100%;
  background-color: #f8f8f8;
  text-align: center;
  padding: 10px 0;
  font-size: 12px;
  color: #666;
  border-top: 1px solid #ddd;
  margin-top: 20px; /* 与页面内容保持距离 */
}
